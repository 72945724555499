import * as React from 'react'
import Layout from '../components/layout'
import { Link, StaticQuery, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

const Cooking = () => {

  return (
    <Layout>

      <section id="hero" className='py-10 sm:py-20 px-8 bg-slate-100'>
        <div className='max-w-4xl mx-auto'>
          <div id="stove-icon-container" className="flex items-center justify-center w-24 h-24 rounded-full ring-1 ring-slate-900/10 shadow">
            <div className='flex items-center justify-center h-[95%] w-[95%] bg-violet-200 p-4 rounded-full'>
              <svg width="75%" height="75%" viewBox="0 0 200 180" version="1.1">
                  <g id="Home-page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="cooking-icon" transform="translate(-150.000000, -160.000000)" fill="#805AD5">
                          <g transform="translate(150.000000, 160.000000)">
                              <path d="M188.888889,0 L11.1111111,0 C4.97579365,0 0,5.01868842 0,10.4612377 L0,167.357789 C0,174.291656 4.97579365,179.310345 11.1111111,179.310345 L188.888889,179.310345 C195.024206,179.310345 200,174.291656 200,168.103448 L200,11.2068966 C200,5.01868842 195.024206,0 188.888889,0 Z M194.444444,168.103448 C194.444444,171.192869 191.953452,173.706897 188.888889,173.706897 L11.1111111,173.706897 C7.89154762,173.706897 5.55555556,171.034932 5.55555556,167.357789 L5.55555556,10.4612377 C5.55555556,8.21609606 7.98146825,5.60344828 11.1111111,5.60344828 L188.888889,5.60344828 C191.953452,5.60344828 194.444444,8.11591441 194.444444,11.2068966 L194.444444,168.103448 Z" id="Shape" fill-rule="nonzero"></path>
                              <polygon id="Path" points="88.6699507 157.635468 94.5812808 157.635468 94.5812808 168.472906 88.6699507 168.472906"></polygon>
                              <polygon id="Path" points="71.9211823 157.635468 77.8325123 157.635468 77.8325123 168.472906 71.9211823 168.472906"></polygon>
                              <polygon id="Path" points="122.167488 157.635468 128.078818 157.635468 128.078818 168.472906 122.167488 168.472906"></polygon>
                              <polygon id="Path" points="105.418719 157.635468 111.330049 157.635468 111.330049 168.472906 105.418719 168.472906"></polygon>
                              <path d="M47.7832512,66.9950739 C61.6580108,66.9950739 72.9064039,55.7466807 72.9064039,41.8719212 C72.9064039,27.9971616 61.6580108,16.7487685 47.7832512,16.7487685 C33.9084917,16.7487685 22.6600985,27.9971616 22.6600985,41.8719212 C22.6600985,55.7466807 33.9084917,66.9950739 47.7832512,66.9950739 Z M47.7832512,22.3316913 C58.5766362,22.3316913 67.3234811,31.0785362 67.3234811,41.8719212 C67.3234811,52.6625147 58.5766362,61.4121511 47.7832512,61.4121511 C36.9898663,61.4121511 28.2430213,52.6625147 28.2430213,41.8719212 C28.2430213,31.0785362 36.9898663,22.3316913 47.7832512,22.3316913 Z" id="Shape" fill-rule="nonzero"></path>
                              <path d="M47.7832512,84.729064 C31.1870834,84.729064 17.7339901,98.4026998 17.7339901,115.270936 C17.7339901,132.139172 31.1870834,145.812808 47.7832512,145.812808 C64.3794191,145.812808 77.8325123,132.139172 77.8325123,115.270936 C77.8325123,98.4026998 64.3794191,84.729064 47.7832512,84.729064 Z M47.7832512,140.25974 C34.205278,140.25974 23.1974922,129.071499 23.1974922,115.270936 C23.1974922,101.470373 34.205278,90.2821317 47.7832512,90.2821317 C61.3612245,90.2821317 72.3690103,101.470373 72.3690103,115.270936 C72.3690103,129.071499 61.3612245,140.25974 47.7832512,140.25974 Z" id="Shape" fill-rule="nonzero"></path>
                              <path d="M140.8867,27.5862069 C118.034286,27.5862069 99.5073892,46.3336617 99.5073892,69.4581281 C99.5073892,92.5825944 118.034286,111.330049 140.8867,111.330049 C163.739113,111.330049 182.26601,92.5825944 182.26601,69.4581281 C182.26601,46.3336617 163.739113,27.5862069 140.8867,27.5862069 Z M140.8867,105.747126 C121.079015,105.747126 105.024631,89.5016186 105.024631,69.4581281 C105.024631,49.4146376 121.079015,33.1691297 140.8867,33.1691297 C160.694384,33.1691297 176.748768,49.4146376 176.748768,69.4581281 C176.748768,89.5016186 160.694384,105.747126 140.8867,105.747126 Z" id="Shape" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </svg>
            </div>
          </div>
          <h1 className='text-4xl lg:text-5xl'>Stoves</h1>
          <p className='mt-4 font-normal text-xl'>
            Most people know that the food they eat affects their health. But <i><b>how</b></i> you cook that
            food also matters. For example, gas stoves create indoor air pollution that damages lungs 
            and increases children's risk of asthma by 40%. 
          </p>
          <p className='mt-4 font-normal text-xl'>
            Read the guides below to learn more about why gas stoves are dangerous and electric cooking options 
            like induction stoves and cooktops are much better in terms of both cooking experience and health. 
          </p>
        </div>
      </section>

      <section id="featured-article" className='py-10 sm:py-20 px-8'> 
        <div className='max-w-4xl mx-auto'>
          <div className='max-w-2xl'>
            <h2 className='text-violet-400 text-2xl font-semibold'>Featured article</h2>
            <Link to="/induction-cooktop-and-stove-guide/" className='no-underline'>
              <h3 className='text-3xl lg:text-4xl max-w-5xl mt-4 mb-6'>Induction Cooktop and Stove Buyer’s Guide</h3>
              <StaticImage 
                src="../images/home-images/induction-cooktop-buyers-guide.png" 
                alt="Induction cooktop and stove buyer's guide image" 
              />
            </Link>
          </div>
        </div>
      </section>

      <section>
        
      <StaticQuery 
        query={graphql`
          query StoveGuides {
            allWpPost(
              sort: {order: DESC, fields: [date]}
              filter: {categories: {nodes: {elemMatch: {slug: {eq: "cooking"}}}}}
            ) {
              edges {
                page: node {
                  id
                  uri
                  title
                  content
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="max-w-4xl mx-auto mt-10 mb-24">
            <div className="max-w-3xl px-8 sm:px-0">
                <h2>Stove, cooktop, and range articles</h2>
                <p>We've spent hundreds of hours doing research on the health impacts of stoves 
                  and reviewing the best stoves, cooktops, and ranges on the market. 
                </p>
                {data.allWpPost.edges.map(({ page }, index) => (
                  <div id="parent-JSX-div" > 
                    <div 
                      id="top-dividing-line" 
                      className='border-t border-slate-200 my-8'
                    ></div>
                    <div 
                      id="content-cards"
                      key={index}
                      className="flex flex-col-reverse sm:items-center sm:flex-row"
                    >
                      <Link to={page.uri} className='sm:max-w-xs'>
                        <GatsbyImage 
                          image={getImage(page.featuredImage.node.localFile.childImageSharp.gatsbyImageData)}
                        />
                      </Link>
                      <Link 
                        to={page.uri}
                        className="text-3xl font-extrabold text-slate-800 no-underline mb-4 sm:pl-4" 
                      >
                        {page.title}
                      </Link>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}

        />
        
      </section>

    </Layout>
  )
}

export default Cooking